// extracted by mini-css-extract-plugin
export var section = "styles-module--section--1QCEa";
export var heroImgWrap = "styles-module--heroImgWrap--2rH2t";
export var heroImg = "styles-module--heroImg--1Q2UN";
export var titleStyle = "styles-module--titleStyle--3iOZn";
export var narrow = "styles-module--narrow--I1JiL";
export var narrow2 = "styles-module--narrow2--3-_3l";
export var subtitleStyle = "styles-module--subtitleStyle--2ygRp";
export var textStyle = "styles-module--textStyle--32bXj";
export var delimiterLine = "styles-module--delimiterLine--h69i1";
export var wrapAddImg = "styles-module--wrapAddImg--3lVz2";
export var addImg = "styles-module--addImg--2JQpe";
export var cardsWrap = "styles-module--cardsWrap--YJ4Ne";
export var cards = "styles-module--cards--3tMUj";
export var medecinsLogo = "styles-module--medecinsLogo--2x3FE";
export var logoAnimWrap = "styles-module--logoAnimWrap--2bGcU";
export var logoAnim = "styles-module--logoAnim--u_Rgg";
export var casinoCut = "styles-module--casinoCut--v1Qgx";