import React from 'react';
import classnames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import useStaticImage from 'hooks/graphql/useStaticImage';
import useDeviceState from 'hooks/useDeviceState';
import { MOBILE_KEY } from 'constants/devices.js';

import {
  heroImgWrap,
  heroImg,
  titleStyle,
  textStyle,
  cards,
  delimiterLine,
  wrapAddImg,
  subtitleStyle,
  section,
  medecinsLogo,
  narrow,
  narrow2,
  logoAnimWrap,
  logoAnim,
  cardsWrap,
  casinoCut,
} from './styles.module.scss';

const Casino = () => {
  const getStaticImage = useStaticImage();

  const deviceState = useDeviceState();

  const image =
    deviceState !== MOBILE_KEY ? 'ocx/casino.png' : 'ocx/casino-cut.png';
  const objectFit = deviceState !== MOBILE_KEY ? 'contain' : 'cover';

  return (
    <section className={section}>
      <div className={heroImgWrap}>
        {/* <GatsbyImage
          className={heroImg}
          image={getImage(getStaticImage(`ocx/casino.png`))}
          alt={''}
          objectFit={objectFit}
        /> */}

        <div className={heroImgWrap}>
          <picture>
            <source
              srcSet="/images/ocx-event-program/casino.webp"
              type="image/webp"
            />

            <source
              srcSet="/images/ocx-event-program/casino.png"
              type="image/png"
            />

            <img
              className={heroImg}
              src="/images/ocx-event-program/casino.png"
              alt=""
            />
          </picture>
        </div>
      </div>

      <h2 className={titleStyle}>
        With Screver, feedback is not left to chance - Winning at the gaming
        table is!
      </h2>

      <p className={textStyle}>
        At our event, you can only win - because what you're playing with is
        Screver paper money. At every station of our Screver event, you'll be
        rewarded with our own currency:
      </p>

      <div className={cardsWrap}>
        <picture>
          <source
            media="(min-width: 577px)"
            srcSet="/images/ocx-event-program/casino-cards-3x.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 577px)"
            srcSet="/images/ocx-event-program/casino-cards-3x.png"
            type="image/png"
          />
          <source
            media="(max-width: 576px)"
            srcSet="/images/ocx-event-program/casino-cards.webp"
            type="image/webp"
          />
          <source
            media="(max-width: 576px)"
            srcSet="/images/ocx-event-program/casino-cards.png"
            type="image/png"
          />

          <img
            className={cards}
            src="/images/ocx-event-program/casino-cards-3x.png"
            alt=""
          />
        </picture>
      </div>

      <p className={textStyle}>
        a hundred for taking a photo with us, more for giving us an interview,
        and most of all for watching a presentation.
      </p>

      <p className={textStyle}>
        However, the collected banknotes do correspond to a real value: what you
        multiply at the gaming tables of the Casino Baden can turn into real
        prizes for you.
      </p>

      <div className={wrapAddImg}>
        <p className={delimiterLine}></p>

        <h2 className={classnames(titleStyle, narrow)}>
          The grand prize is a Screver subscription
        </h2>

        <div className={logoAnimWrap}>
          <img
            className={logoAnim}
            src="/images/ocx-event-program/casino-logo-anim.gif"
            alt=""
          />
        </div>
      </div>

      <p className={textStyle}>
        and consulting package worth several 10,000 francs: your company will
        receive free access to the services of our feedback specialists for a
        whole year. Then, winning has nothing to do with luck anymore.
      </p>

      <p className={delimiterLine}></p>

      <GatsbyImage
        className={medecinsLogo}
        image={getImage(getStaticImage(`ocx/casino-medecins-logo.png`))}
        alt={''}
        objectFit="contain"
        objectPosition="right"
      />

      <h2 className={titleStyle}>
        The second prize is a donation of 2,000 francs
      </h2>

      <p className={textStyle}>
        which we will transfer on your behalf and in the name of your company to
        the organization "Doctors Without Borders" (Medecins Sans Frontieres).
        This way, you're giving back some luck yourself.
      </p>

      <p className={delimiterLine}></p>

      <div className={wrapAddImg}>
        <h3 className={classnames(subtitleStyle, narrow)}>
          Challenge your luck
        </h3>

        <p className={classnames(textStyle, narrow2)}>
          under the professional guidance of the game masters from Casino Baden,
          you can place your bets on the right number in roulette, try to get
          the lucky number 21 in blackjack, or outplay your opponents in poker:
          all in!
        </p>

        <h3 className={subtitleStyle}>Drawing of the lucky winner</h3>

        <p className={textStyle}>
          But even if you lose all your chips, you still have another chance to
          win: you can throw one or your last game chip into a box, to be drawn
          by the lucky winner - prizes include an MS Sports Camp of your choice,
          and five bottles of top champagne from André Jacquart (Mesnil
          Expérience, zéro dosage - 75 cl).
        </p>

        <GatsbyImage
          className={casinoCut}
          image={getImage(getStaticImage(image))}
          alt={''}
          objectFit={objectFit}
          objectPosition="right"
        />
      </div>

      <h3 className={subtitleStyle}>Good Luck!</h3>
    </section>
  );
};

export default Casino;
