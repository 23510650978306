import React from 'react';

import HeaderCommon from 'sections/ScreverOcxProgram/HeaderCommon';
import Casino from 'sections/ScreverOcxProgram/Casino';
import FooterCommon from 'sections/ScreverOcxProgram/FooterCommon';
import SEO from 'sections/Layout/SEO';
import OcxHelmet from 'sections/Layout/SEO/OcxHelmet';

const PAGES = [
  {
    title: 'Program',
    url: '/events/screver-ocx-program/program',
  },
  {
    title: 'Participants',
    url: '/events/screver-ocx-program/participants',
  },
  {
    title: '✌🏻Feedback',
    url: '/events/screver-ocx-program/feedback',
  },
];

const seo = {
  title: 'OCX Event | Casino | Screver Feedback Management Solution',
  metaDesc:
    'Get the most out of your event by having an idea of the agenda to follow. With Screver, feedback is not left to chance - Winning at the gaming table is!',
  opengraphUrl:
    'https://screver.com/events/screver-ocx-program/casino-instructions/',
  image:
    'https://wp.screver.com/wp-content/uploads/2023/08/screver-ocx-meta-img.jpeg',
};

const CasinoInstructions = () => {
  return (
    <>
      <OcxHelmet />

      <SEO seo={seo} themeColor={'#151517'} />

      <div className="scr-ocx-program">
        <HeaderCommon title="Casino instructions" />

        <main>
          <div className="ocx-prog-wrap">
            <Casino />
          </div>
        </main>

        <FooterCommon linksToPages={PAGES} />
      </div>
    </>
  );
};

export default CasinoInstructions;
